import {Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

let openSnackbarFn;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Notifier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      alert_props: {message:'', severity:'info', color:'info'},
    };
  }

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  handleSnackbarRequestClose = () => {
    this.setState({
      open: false,
      alert_props: this.state.alert_props,
    });
  };

  openSnackbar = ( alert_props ) => {
    this.setState({ open: true, alert_props: alert_props });
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={this.state.open}
        autoHideDuration={5000}
        onClose={this.handleSnackbarRequestClose}
        >
          <Alert onClose={this.handleSnackbarRequestClose} severity={this.state.alert_props.severity} color={this.state.alert_props.color}>
          {this.state.alert_props.message}
          </Alert>
      </Snackbar>
    );
  }
}

export function openSnackbarExported( alert_props ) {
  openSnackbarFn( alert_props );
}

export default Notifier;