import SvgIcon from '@material-ui/core/SvgIcon';

export default function WarehousingIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 165.52 156.55">
			  <path d="M112.85 147.12H71.53a14.2 14.2 0 0 0 1.09-5.47 14.43 14.43 0 1 0-27.77 5.47H33.08a4.23 4.23 0 0 1-4.24-4.23v-19a4.27 4.27 0 0 1 1-2.73l27-32.11a4.23 4.23 0 0 1 3.24-1.51h23.08a4.23 4.23 0 0 1 4.24 4.23v25.76a3.11 3.11 0 0 0 3.11 3.11h2.56a3.11 3.11 0 0 0 3.11-3.11V68.02A10.32 10.32 0 0 1 106.5 57.7h43.61L86.31 1.35a5.36 5.36 0 0 0-7.1 0L.91 70.51a2.69 2.69 0 0 0-.91 2v81.35a2.68 2.68 0 0 0 2.68 2.69h160.16a2.68 2.68 0 0 0 2.68-2.69v-6.75Zm-54.66 3.72a9.19 9.19 0 1 1 9.19-9.19 9.2 9.2 0 0 1-9.19 9.19Z"/>
			</svg>
		</SvgIcon>
	)
}