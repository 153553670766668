import SvgIcon from '@material-ui/core/SvgIcon';

export default function PersonDataIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 165.18 163.7">
			  <defs/>
			  <path d="M172.58 18.15h-20a10 10 0 0 0-10 10.08 10 10 0 1 1-20 0 10.05 10.05 0 0 0-10-10.08h-25a10.05 10.05 0 0 0-10 10.08 10 10 0 1 1-20 0 10 10 0 0 0-10-10.08h-20a10 10 0 0 0-10 10.08v115.89a10 10 0 0 0 10 10.08h145a10 10 0 0 0 10-10.08V28.23a10 10 0 0 0-10-10.08ZM60.65 64a14.58 14.58 0 1 1-14.48 14.52A14.52 14.52 0 0 1 60.65 64ZM85 120.8a3.47 3.47 0 0 1-3.33 3.61h-42a3.47 3.47 0 0 1-3.33-3.61v-3.43c0-6.56 4.9-11.88 11-11.88H74c6 0 11 5.32 11 11.88Zm67.58-3.13h-45a6.3 6.3 0 0 1 0-12.6h45.05a6.3 6.3 0 0 1 0 12.6Zm0-25.2h-45a6.3 6.3 0 0 1 0-12.6h45.05a6.3 6.3 0 0 1 0 12.6ZM167.58 166.73H27.42a7.56 7.56 0 0 0 0 15.12h140.16a7.56 7.56 0 0 0 0-15.12Z" class="cls-1" transform="translate(-17.41 -18.15)"/>
			</svg>
		</SvgIcon>
	)
}