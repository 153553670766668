import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
//import useStyles from '../styles';
import { makeStyles } from '@material-ui/core/styles';

const useCStyles = makeStyles((theme) => ({
	cSpinner: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translateX(-50%) translateY(-50%)',
	}
}));

const LoadingIndicator = () => {
	const classes = useCStyles();
    return (
	<div className={classes.cSpinner}>
	    <CircularProgress size={59.5} />
	</div>
    );
};

export default LoadingIndicator;

