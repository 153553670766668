import SvgIcon from '@material-ui/core/SvgIcon';

export default function BillingIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 137.15 174.13">
			  <defs/>
			  <path d="m138.07 16-10 5.48a4.57 4.57 0 0 1-4.34 0l-14.57-8a4.54 4.54 0 0 0-4.35 0l-14.59 7.95a4.52 4.52 0 0 1-4.34 0l-14.54-7.94a4.54 4.54 0 0 0-4.35 0l-14.53 7.94a4.54 4.54 0 0 1-4.35 0L38.14 16a4.54 4.54 0 0 0-6.71 4v145.24a4.53 4.53 0 0 0 6.7 4l10-5.47a4.52 4.52 0 0 1 4.34 0l14.58 7.94a4.52 4.52 0 0 0 4.34 0L80 167a52 52 0 0 1 64.8-70V19.94a4.53 4.53 0 0 0-6.73-3.94ZM60.89 126.07h-6.8a5.67 5.67 0 0 1 0-11.34h6.8a5.67 5.67 0 1 1 0 11.34Zm13.28-22.67H54.09a5.67 5.67 0 0 1 0-11.34h20.08a5.67 5.67 0 0 1 0 11.34ZM121 80.73H54.09a5.67 5.67 0 0 1 0-11.34H121a5.67 5.67 0 0 1 0 11.34Zm0-22.67H54.09a5.67 5.67 0 0 1 0-11.33H121a5.67 5.67 0 1 1 0 11.33ZM128.44 130.92h-3.2V145h3.2a7 7 0 1 0 0-14.05Z" class="cls-1" transform="translate(-31.43 -12.94)"/>
			  <path d="M127.77 105.45a40.81 40.81 0 1 0 40.8 40.81 40.8 40.8 0 0 0-40.8-40.81ZM144.34 138c0 .45 0 .9-.07 1.35h3.1c1.74 0 3.16 1.7 3.16 3.79s-1.42 3.79-3.16 3.79h-5.79a15.87 15.87 0 0 1-13.14 7h-3.2v12.58a4.43 4.43 0 1 1-8.86 0v-19.63h-3.54c-1.61 0-2.93-1.7-2.93-3.79s1.32-3.79 2.93-3.79h3.54v-2.52h-3.54c-1.61 0-2.93-1.7-2.93-3.79s1.32-3.79 2.93-3.79h3.54v-2.71a4.43 4.43 0 0 1 4.25-4.42h7.81a15.89 15.89 0 0 1 13.26 7.15h5.67c1.74 0 3.16 1.7 3.16 3.79s-1.42 3.79-3.16 3.79h-3.09c.03.37.06.75.06 1.2Z" class="cls-1" transform="translate(-31.43 -12.94)"/>
			</svg>
		</SvgIcon>
	)
}