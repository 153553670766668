import { axios } from '../utils/axios';
import authHeader from './auth_header';

class PersonsService {

  AddPerson(personData, token) {
    return axios.post('/api/persons/addperson', personData, { headers: authHeader(token) })
  }

  EditPerson(personData, token) {
    return axios.patch('/api/persons/editperson', personData, { headers: authHeader(token) })
  }

  DeletePerson(personData, token) {
    return axios.delete('/api/persons/deleteperson', { params: { personData }, headers: authHeader(token) })
  }

  SearchPerson(keyName, token) {
    return axios.get('api/persons/searchperson', { params: { search_key: keyName, token } }) //get not using authorization header
    //return axios.get('api/persons/searchperson/'+keyName, { headers: authHeader(token) }); //get using authorization header
  }

  ListUserPermission(objData, userToken) {
    return axios.get('/api/persons/listpermission', {
      //timeout: 100,
      params: {
        objData,
        token: userToken,
      },
    }, { headers: authHeader(userToken) });
  }

  ListUsersGroup(token) {
    return axios.get('api/persons/listusersgroup', { headers: authHeader(token) });
  }

  ListUserGroupPermissions(objData, userToken) {
    return axios.get('/api/persons/list_ug_permissions', {
      params: {
        objData,
        token: userToken,
      },
    }, { headers: authHeader(userToken) });
  }

  EditUserPermissions(objData, token) {
    return axios.patch('/api/persons/edit_user_permissions', objData, { headers: authHeader(token) })
  }

  RevokeUserPermissions(objData, token) {
    return axios.delete('/api/persons/revoke_user_permissions', { params: { objData }, headers: authHeader(token) })
  }

  RemoveUserAccount(objData, token) {
    return axios.delete('/api/persons/remove_user_account', { params: { objData }, headers: authHeader(token) })
  }

  GetUserSettings(userToken) {
    return axios.get('/api/persons/get_user_settings', {
      params: {
        token: userToken,
      },
    }, { headers: authHeader(userToken) });
  }

  EditUserSettings(objData, token) {
    return axios.patch('/api/persons/edit_user_settings', objData, { headers: authHeader(token) })
  }

  GetProfileLastUpdate(objData, userToken) {
    return axios.get('/api/persons/get_user_profile_lastupdate', {
      params: {
        objData,
        token: userToken,
      },
    }, { headers: authHeader(userToken) });
  }

  GetUserProfile(objData, userToken, source) {
    return axios.get('/api/persons/get_user_profile', {
      cancelToken: source.token,
      params: {
        objData,
        token: userToken,
      },
    }, { headers: authHeader(userToken) });
  }

  EditProfileProps(objData, token) {
    return axios.patch('/api/persons/edit_profile_props', objData, { headers: authHeader(token) })
  }

  ListUser(keyName, mode, token) {
    return axios.get('api/persons/list_user', { params: { search_key: keyName, list_mode: mode, token } }) //get not using authorization header
  }

}

export default new PersonsService();