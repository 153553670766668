import React, { createContext, useState, useRef } from 'react';
import { base64decode } from '../utils/base64';
import { capitalizeString } from '../utils/utils';
import AuthService from '../services/auth_service';
import PersonsService from '../services/persons_service';
import AppsIcon from '@material-ui/icons/Apps';
import MCOIcon from '../svg/MCOIcon';
import AccessMgtIcon from '../svg/AccessMgtIcon';
import PersonDataIcon from '../svg/PersonDataIcon';
import DataStoreIcon from '../svg/DataStoreIcon';
import BillingIcon from '../svg/BillingIcon';
import WarehousingIcon from '../svg/WarehousingIcon';
import AssetMgtIcon from '../svg/AssetMgtIcon';
import CalendarIcon from '../svg/CalendarIcon';
import EWalletIcon from '../svg/EWalletIcon';
import AppWorkIcon from '../svg/AppWorkIcon';

export const GuestContext = createContext();

const GuestContextProvider = (props) => {

  const [guest, setGuest] = useState({ emailValue: '', contactEditorValue: '{}' })
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [isDDMenuOpen, setDDMenuOpen] = useState(false)
  const [isULDrawerOpen, setULDrawerOpen] = useState(false)
  const [userAvatar, setUserAvatar] = useState('null.png') //false img url
  const [userAvatarColor, setUserAvatarColor] = useState('grey') 
  const [firstName, setFirstName] = useState('Anonymous')
  const [currentView, setCurrentView] = useState(null) //use to find current selected view as reference by drawer menu items
  const [loginFlag, setLoginFlag] = useState(false)
  const [isLoggedOut, setIsLoggedOut] = useState(false)
  const [logoutMsg, setLogoutMsg] = useState(null)
  const [vPortWidth, setVPortWidth] = useState(600)
  const [idleMinutes, setIdleMinutes] = useState(15) //logout in idle time (minutes)
  const [RTMinutes, setRTMinutes] = useState(10) //call refreshToken in this interval in minutes
  const [showConfirmPwdDialog, setShowConfirmPwdDialog] = useState(false)
  const [isPrimaryPwd, setIsPrimaryPwd] = useState(true)
  const [isConfirmUserPwd, setIsConfirmUserPwd] = useState(false)//user showConfirmDialog succed on password check
  const [isConfirmUserPwdCancel, setIsConfirmUserPwdCancel] = useState(false)
  const [OTPResult, setOTPResult] = useState(undefined)
  const [securityOptions, setSecurityOptions] = useState(undefined)
  const [profileImg, setProfileImg] = useState(undefined)
  const [profilePic, setProfilePic] = useState(undefined)
  const [profileImgBG, setProfileImgBG] = useState('grey')
  const [profileAvatarColor, setProfileAvatarColor] = useState('orange')
  const [logonUser, setLogonUser] = useState('') //email of currently logonUser
  const [profileEmail, setProfileEmail] = useState(null)

  const socketN = useRef(null) //websocket global handle as ref
  const userListToogleClicked = useRef(null)

  const userTokenEx = useRef(null) //global token as ref
  const contentRef = useRef(null) //the content Viewport reference by <div> element within InNavBar component block declaration @GuestLayout

  const setGuestValue = (newValue) => {
    setGuest(newValue)
  }

  const setUserTokenValue = (newValue) => {
    if (newValue) {
      var mixToken = newValue.split('.')
      setFirstName(capitalizeString(base64decode(mixToken[1])))
      //setUserAvatar(base64decode(mixToken[2]))
    } else {
      setFirstName('Anonymous')
      //setUserAvatar('null.png') //false img url  
    }
    //userTokenEx.current = newValue
    const token = {
      tokenValue: newValue,
    }
    localStorage.setItem('userToken', JSON.stringify(token))
    setLoginFlag(!loginFlag)
  }

  const setDrawerState = (newValue) => {
    setIsDrawerOpen(newValue)
  }

  const setDDMenuState = (newValue) => {
    setDDMenuOpen(newValue)
  }
  //---------------------------------------------------
  const logOutUser = () => {
    const aToken = userTokenEx.current
    userTokenEx.current = null
    localStorage.removeItem('userToken')
    localStorage.removeItem('dirState')
    localStorage.removeItem('idle-timer')
    //localStorage.clear() //remove all items
    setIsDrawerOpen(true)
    setDDMenuOpen(false)
    setLoginFlag(!loginFlag)
    setIsLoggedOut(true)
    AuthService.RevokeToken(aToken)
      .then(response => {
        //console.log(response.data)
        return Promise.resolve(response)
      })
      .catch((error) => {
        let errStr = error.toString()
        let errCode = 0
        if (error.response) {
          errCode = error.response.status
          const errText = error.response.data.error ? error.response.data.error : error.response.statusText
          errStr = errCode.toString() + ' - ' + errText
        }
        if (errStr === `Error: Network Error`) {
          errStr = `Network Error`
        }
        //console.log(errStr)
        return Promise.reject(errStr)
      });
    if (socketN.current) {
      const loginUser = JSON.parse(localStorage.getItem('lastAccessInfo'))
      const conInfo = {
            broadcastType: 'disconnection',
            sender: loginUser.login_name,
          }
      socketN.current.emit('broadcast', JSON.stringify(conInfo));
      socketN.current.emit('bye');
      socketN.current = null
    }   
  }

  const [globalUserPermissions,setGlobalUserPermissions] = useState(null)
  const [GUPFetchError, setGUPFetchError] = useState(null) //global User Permission FetchError
  const [isFetchingGUP, setIsFetchingGUP] = useState(false) //state of fetching global User Permission 

  const getCurrentUserPermissions = (userEmail) => {
    setGUPFetchError(null)
    setIsFetchingGUP(true)
    PersonsService.ListUserPermission({ data: userEmail }, userTokenEx.current)
      .then(function(response) {
        if (response.data) {
          let PList = response.data.Permissions
          PList.sort((a, b) => {
            let titleA = a.title.toLowerCase() // ignore case
            let titleB = b.title.toLowerCase() // ignore case
            if (titleA < titleB) {
              return -1
            }
            if (titleA > titleB) {
              return 1
            }
            return 0 // titles must be equal
          })
          const userObj = {
            UFS_MAX: response.data.UFS_MAX,
            Permissions: PList
          }
          setGlobalUserPermissions(userObj)
        } else {
          throw new Error(response)
        }
      })
      .catch((error)=> {
        setGUPFetchError(error)
      })
      .finally(()=>setIsFetchingGUP(false))
  }

  const checkUnauthorizedError = (errorCode) => {
    if (errorCode === 401) {
      const logOutResult = logOutUser() //or redirect to a custom 401 page
      console.log(logOutResult)
    }
  }

  const appItems = [
    {
      text: `Consumer's Welfare`,
      hint: 'Member/Consumer Assistance Desk',
      icon: <MCOIcon fontSize="large" />,
      status: 'open',
      path: '/app-cwf',//temp
    },
    {
      text: 'E-Billing',
      hint: 'Electric Billing and Monitoring',
      icon: <BillingIcon fontSize="large" />,
      status: 'lock',
      path: '/apps',//temp
    },
    {
      text: 'Warehousing',
      hint: 'Warehousing/Inventory tracking',
      icon: <WarehousingIcon fontSize="large" />,
      status: 'lock',
      path: '/apps',//temp
    },
    {
      text: 'Human Resource',
      hint: 'Human Resource Management',
      icon: <AppsIcon fontSize="large" />,
      status: 'under_development',
      path: '/apps',//temp
    },
    {
      text: 'Property Manager',
      hint: 'Placed-In-Service Identification, Depreciation and Revaluation',
      icon: <AssetMgtIcon fontSize="large" />,
      status: 'under_development',
      path: '/apps',//temp
    },
    {
      text: 'Project Manager',
      hint: 'Project Construction Costing and Monitoring',
      icon: <AppsIcon fontSize="large" />,
      status: 'under_development',
      path: '/apps',//temp
    },
    {
      text: 'A/P Calendar',
      hint: 'Accounts Payable Scheduling',
      icon: <CalendarIcon fontSize="large" />,
      status: 'under_development',
      path: '/apps',//temp
    },
    {
      text: 'General Ledger',
      hint: 'Consolidated Accounting Journals',
      icon: <AppWorkIcon fontSize="large" />,
      status: 'lock',
      path: '/apps',//temp
    },
    {
      text: 'Budget',
      hint: 'Budget Monitoring',
      icon: <AppsIcon fontSize="large" />,
      status: 'under_development',
      path: '/apps',//temp
    },
    {
      text: 'E-Wallet',
      hint: 'Collection Agents Funding, Withdrawal & Auditing',
      icon: <EWalletIcon fontSize="large" />,
      status: 'under_development',
      path: '/apps',//temp
    },
    {
      text: 'PersonData',
      hint: 'Basic person data manager',
      icon: <PersonDataIcon fontSize="large" />,
      status: 'open',
      path: '/app-persons-data',//temp
    },
    {
      text: 'Access Management',
      hint: 'Users Audit & Authorization',
      icon: <AccessMgtIcon fontSize="large" />,
      status: 'open',
      path: '/app-access-mgt',//temp
    },
    {
      text: 'System Data Store',
      hint: 'Data Storage & Configurations',
      icon: <DataStoreIcon fontSize="large" />,
      status: 'open',
      path: '/app-data-store',
    },
  ]

  return (
    <GuestContext.Provider value={{
    userTokenEx, guest, setGuestValue, setUserToken: setUserTokenValue, 
    userAvatar, setUserAvatar, userAvatarColor, setUserAvatarColor, 
    firstName, contentRef, socketN,
    isDrawerOpen, isDDMenuOpen, setDrawerState, 
    setDDMenuState, currentView, setCurrentView,
    isLoggedOut, setIsLoggedOut, logOutUser, 
    logoutMsg, setLogoutMsg, 
    checkUnauthorizedError, 
    RTMinutes, setRTMinutes, 
    vPortWidth, setVPortWidth, 
    globalUserPermissions,
    getCurrentUserPermissions, 
    isFetchingGUP, 
    GUPFetchError,
    setGUPFetchError,
    idleMinutes, 
    setIdleMinutes,
    showConfirmPwdDialog, 
    setShowConfirmPwdDialog,
    isPrimaryPwd, 
    setIsPrimaryPwd,
    isConfirmUserPwd, 
    setIsConfirmUserPwd,
    isConfirmUserPwdCancel, 
    setIsConfirmUserPwdCancel,
    OTPResult,
    setOTPResult,
    securityOptions, 
    setSecurityOptions,
    profileImg, 
    setProfileImg,
    profilePic, 
    setProfilePic,
    profileImgBG, 
    setProfileImgBG,
    profileAvatarColor, 
    setProfileAvatarColor,
    userListToogleClicked,
    logonUser, 
    setLogonUser,
    profileEmail, 
    setProfileEmail,
    appItems,
    isULDrawerOpen, 
    setULDrawerOpen,
    }}>
    {props.children}
    </GuestContext.Provider>
  );
};

export default GuestContextProvider;