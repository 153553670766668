import React, { createContext, useState } from 'react';

export const ConsumerContext = createContext();

const ConsumerContextProvider = (props) => {

  const [currentAccount, setCurrentAccount] = useState('')
  const [lastAccountLedger, setLastAccountLedger] = useState(undefined)
  const [oUPDetails, setOUPDetails] = useState([])
  const [accountList, setAccountList] = useState([])
  const [aNameValue,setANameValue] = useState('')
  const [aSearchOptionFirst, setASearchOptionFirst] = useState(true)
  const [bottomPeriod, setBottomPeriod] = useState('')
  const [bPeriodArray, setbPeriodArray] = useState([])
  const [isAccountLoading, setIsAccountLoading] = useState(false)
    
  return (
    <ConsumerContext.Provider value={{
      currentAccount,
      setCurrentAccount,
      lastAccountLedger,
      oUPDetails, 
      setOUPDetails, 
      setLastAccountLedger,
      accountList, 
      setAccountList,
      aNameValue,
      setANameValue,
      aSearchOptionFirst, 
      setASearchOptionFirst,
      bottomPeriod, 
      setBottomPeriod,
      bPeriodArray, 
      setbPeriodArray,
      isAccountLoading, 
      setIsAccountLoading,
      }}>
      {props.children}
    </ConsumerContext.Provider>
  );
};

export default ConsumerContextProvider;