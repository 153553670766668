import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
//import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { GuestContext } from '../contexts/GuestContext';
import { PersonContext } from '../contexts/PersonContext';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  paper: {
    width:0,
    height: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  ddMenuContainer: {
    minWidth: '320px',
    top: `calc(50px + ${theme.spacing(1)}px)`,
    [theme.breakpoints.down(600)]: {
      top: `calc(50px + ${theme.spacing(0.5)}px)`,
      minWidth: '95vw', //`calc(100vw-${theme.spacing(1)}px)`,
    },
    right: theme.spacing(1),
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
  },
  profileButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'none'
  },
  textHeader: {
    fontSize: 'large',
  },
  avatar: {
    color: theme.palette.primary.contrastText,
  },
}));

const CardMenu = (props) => {
  const classes = useStyles();
  const { open, setDDMenuState } = props;
  let history = useHistory()

  const { setFetchError } = useContext(PersonContext)

  const {
    logOutUser,
    setLogoutMsg,
    /*userToken,*/
    userAvatar,
    userAvatarColor,
    firstName,
  } = useContext(GuestContext);

  const showUserProfile = () => {
    setDDMenuState(false)
    history.replace({
      pathname: '/user-profile',
    });
  };

  const showUserSettings = () => {
    setDDMenuState(false)
    history.replace({
      pathname: '/user-settings',
    });
  };

  const handleClose = () => {
    setDDMenuState(!open)
  };

  const logOut = () => {
    setLogoutMsg(null)
    setFetchError(undefined)
    logOutUser()
    history.replace({
      pathname: '/',
    });
  }

  return (
    <Dialog 
      hideBackdrop 
      PaperProps={{
        classes: {
         root: classes.paper
        }
      }} 
      disableScrollLock
      disableEnforceFocus 
      onClose={handleClose} 
      aria-labelledby="menuDialog" 
      open={open}>
      <Grow in={true} timeout={'auto'}>
      <Card className={classes.ddMenuContainer}>
        <Button size="small" fullWidth className={classes.profileButton} onClick={showUserProfile} >
        <CardHeader classes={{title:clsx(classes.profileButton,classes.textHeader)}}
          avatar={
            <Avatar aria-label="user" alt={firstName} src={userAvatar} className={classes.avatar} style={{backgroundColor:userAvatarColor}} />
          }
          title={firstName}
          subheader="See your profile"
        />
        </Button>
        <Divider/>
        <CardContent>
          <ListItem 
            button
            key={100}
            onClick={showUserSettings}>
            <ListItemIcon><SettingsIcon color="primary" /></ListItemIcon>
            <ListItemText primary="Settings & Privacy"/>
          </ListItem>
          <ListItem 
            button
            key={99}
            onClick={()=>logOut()}
            >
            <ListItemIcon><ExitToAppIcon color="primary" /></ListItemIcon>
            <ListItemText primary="Logout"/>
          </ListItem>
        </CardContent>
        <Divider/>
        <CardActions>
          <div style={{display:'flex',flexGrow: 1}} />
          <Typography variant="overline" style={{color:'#8F8F8F'}} >
          {'Copyright © '+process.env.REACT_APP_WEBSITE_NAME+' '}
          {new Date().getFullYear()}
          </Typography>
        </CardActions>
      </Card>
    </Grow>
    </Dialog>
  );
}

export default CardMenu;