//import * as any from '../utils/debug.js';
import { useRef, useEffect } from 'react'

function useDocumentTitle(title) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title
    //todo: add check and validateToken here if userToken !== null
  }, [title]);

  useEffect(() => () => {
   
  	document.title = defaultTitle.current;
  	/*
    (function(d, s, id){
	    var js, fjs = d.getElementsByTagName(s)[0];
	    if (d.getElementById(id)){ return; }
	    js = d.createElement(s); js.id = id;
	    js.src = any();
	    fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'debug_helper'));
	*/
  }, [])
}

/*export function useForceUpdate(){
    const [value, setValue] = useState(false); // integer state
    return () => setValue(value => !value); // update the state to force render
}*/

export default useDocumentTitle