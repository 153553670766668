import SvgIcon from '@material-ui/core/SvgIcon';

export default function EWalletIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 165.1 164.72">
			  <defs/>
			  <path d="M139.76 109.1a12.19 12.19 0 0 0-9.36 2.9 10.12 10.12 0 0 0-7.91-7.19 12 12 0 0 0-8.94 2.58 11.1 11.1 0 0 0-8.32-6.9 12.1 12.1 0 0 0-10.09 3.78V77a8.85 8.85 0 0 0-5.81-8.56 8.66 8.66 0 0 0-11.45 8.19v69.06a8.63 8.63 0 0 1-8.63-8.69v-7.8a3 3 0 0 0-3-3h-2.6A11.66 11.66 0 0 0 52 137.92v21.31a12.93 12.93 0 0 0 4.39 9.77L68 179.15a12.93 12.93 0 0 0 8.53 3.21h47.76a12.94 12.94 0 0 0 9.16-3.79l9.68-9.68a12.94 12.94 0 0 0 3.79-9.16v-41.65a9 9 0 0 0-7.16-8.98Zm-72.67-8.75H56.3A4.31 4.31 0 0 1 52 96V44.24a4.32 4.32 0 0 1 4.32-4.32H97a50.23 50.23 0 0 1 6.92-13h-69.2a17.27 17.27 0 0 0-17.27 17.32V96a17.27 17.27 0 0 0 17.27 17.3h32.37Zm-29-22.94a7.27 7.27 0 1 1 7.27-7.27 7.28 7.28 0 0 1-7.24 7.27ZM145.24 43.84h-3.07v13.49h3.07a6.75 6.75 0 0 0 0-13.49Z" class="cls-1" transform="translate(-17.45 -17.64)"/>
			  <path d="M143.7 17.64a38.85 38.85 0 1 0 38.85 38.85 38.84 38.84 0 0 0-38.85-38.85Zm16.79 33c0 .43 0 .86-.07 1.29h3c1.67 0 3 1.63 3 3.64a3.37 3.37 0 0 1-3 3.63h-5.55a15.27 15.27 0 0 1-12.61 6.69h-3.07v12.02a4.26 4.26 0 0 1-8.51 0V59.15h-3.39c-1.56 0-2.82-1.62-2.82-3.63s1.26-3.64 2.82-3.64h3.39v-2.42h-3.39c-1.56 0-2.82-1.62-2.82-3.63s1.26-3.64 2.82-3.64h3.39v-2.6a4.24 4.24 0 0 1 4.08-4.24h7.5A15.26 15.26 0 0 1 158 42.19h5.44c1.67 0 3 1.63 3 3.64a3.37 3.37 0 0 1-3 3.63h-3c.02.38.05.75.05 1.13Z" class="cls-1" transform="translate(-17.45 -17.64)"/>
			</svg>
		</SvgIcon>
	)
}