import moment from "moment";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("MM.DD.YYYY hh:mm A");
  return buildDate;
}

export const getCurrentYYYYMM = () => {
  const cYYYYMM = moment().format("YYYYMM");//moment().format("dddd, MMMM Do YYYY, h:mm:ss a");
  return cYYYYMM;
}

export const dateFormatYYYYMM = (pDate) => {
  const cYYYYMM = moment(pDate).format("YYYYMM");//moment().format("dddd, MMMM Do YYYY, h:mm:ss a");
  return cYYYYMM;
}

export const numberFormat = (decimals, dec_point, thousands_sep)=> {
    dec_point = typeof dec_point !== 'undefined' ? dec_point : '.';
    thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : ',';

    var parts = decimals.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
}

/*export const capitalizeFirstLetter = (string)=> {
  return string.charAt(0).toUpperCase() + string.slice(1);
}*/

export const capitalizeString = (string) => {
  return string.replace(
    /\w\S*/g,
    (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const escapeRegex = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const removeSymbols = (string) => {
  return string.replace(/[^0-9a-zA-Z,ñÑ*]+/gu, '');
}

export const dateToUnix =(dateInput)=> {
    var unixTimestamp = moment(dateInput).unix()
    if (isNaN(unixTimestamp) === false) {
      return unixTimestamp
    }
    return 0
  }

//npm install xregexp
//https://xregexp.com/api/