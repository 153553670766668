import SvgIcon from '@material-ui/core/SvgIcon';

export default function AssetMgtIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 163.17 164.72">
			  <defs/>
			  <path d="M179.08 114.32a11.59 11.59 0 0 0-16.24-1.93l-32.75 25.66a9.23 9.23 0 0 1-5.7 2h-23.47a5.78 5.78 0 1 1 0-11.56h19.66a11.57 11.57 0 0 0 0-23.14h-48a27.81 27.81 0 0 0-17.14 5.94l-16.17 12.62a2.32 2.32 0 0 0-.38 3.26l31.21 39.47a2.28 2.28 0 0 0 1.79.88l37.76.32 10.44.09a27.74 27.74 0 0 0 17.45-6l39.64-31.34a11.59 11.59 0 0 0 1.9-16.27ZM29.81 134.34a2.31 2.31 0 0 0-3.25-.38l-7.26 5.74a2.3 2.3 0 0 0-.38 3.25l30.46 38.53a2.3 2.3 0 0 0 3.25.38l7.26-5.74a2.3 2.3 0 0 0 .38-3.25ZM124.35 86.27h-20.54a3.47 3.47 0 0 0-3.47 3.47v.5a3.47 3.47 0 0 0 3.47 3.47h3.41v.84a6.41 6.41 0 0 0 6.41 6.41h.92a6.41 6.41 0 0 0 6.45-6.41v-.84h3.39a3.47 3.47 0 0 0 3.47-3.47v-.5a3.47 3.47 0 0 0-3.51-3.47ZM110.89 17.82c-11.55 1.3-22.67 12.36-24 23.91a27.28 27.28 0 0 0 7.5 22.37 17.59 17.59 0 0 1 4.93 12.22 4.21 4.21 0 0 0 4.22 4.21h21.08a4.21 4.21 0 0 0 4.22-4.21 17.31 17.31 0 0 1 4.83-12.13 27.39 27.39 0 0 0-22.78-46.38Zm.52 52.18a2.67 2.67 0 0 1-5.34 0V58.43a6.6 6.6 0 0 0-6.59-6.59 2.67 2.67 0 0 1 0-5.34 11.94 11.94 0 0 1 11.93 11.93Zm17.27-18.14a6.6 6.6 0 0 0-6.59 6.59V70a2.67 2.67 0 0 1-5.34 0V58.43a11.94 11.94 0 0 1 11.93-11.93 2.67 2.67 0 0 1 0 5.34Z" class="cls-1" transform="translate(-18.42 -17.64)"/>
			</svg>
		</SvgIcon>
	)
}