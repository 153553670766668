import React, { createContext, useState } from 'react';
import Notifier, { openSnackbarExported } from '../components/Notifier';
import { dateToUnix } from '../utils/utils';

export const NotifierContext = createContext();

const NotifierContextProvider = (props) => {

  const [notificationQueue, setNotificationQueue] = useState([])
  const [notifyTrigger, setNotifyTrigger] = useState(null)

  const newNotification =(Type, Message, Sender)=> {
    let NotificationObj = {
      Type,
      Message,
      UnixTimeEntry: dateToUnix(new Date()),
      Sender
    }
    setNotifyTrigger(NotificationObj)
  }

  const showSnackbar = (message, color, severity) => {
    openSnackbarExported(message, color, severity)
  }

  const showErrorAlert = (error, remarks = ``) => {
    let errStr = error.toString()
    let errCode = 0
    if (error.response) {
      errCode = error.response.status
      const errText = error.response.data.error ? error.response.data.error : error.response.statusText
      errStr = errCode.toString() + ' - ' + errText
    }
    if (errStr === `Error: Network Error`) {
      errStr = `Network Error`
    }
    if (remarks !== ``) {
      errStr += ` while ` + remarks
    }
    const alertParam = {
      message: errStr,
      severity: "error",
      color: "error"
    }
    showSnackbar(alertParam)
    return errCode
  }

  const getErrorString = (error) => {
    let errStr = error.toString()
    let errCode = 0
    if (error.response) {
      errCode = error.response.status
      const errText = error.response.data.error ? error.response.data.error : error.response.statusText
      errStr = errCode.toString() + ' - ' + errText
    }
    if (errStr === `Error: Network Error`) {
      errStr = `Network Error`
    }
    return errStr
  }

  return (
    <NotifierContext.Provider value={{
      showSnackbar,
      showErrorAlert, 
      getErrorString,
      notificationQueue, 
      setNotificationQueue,
      notifyTrigger, 
      setNotifyTrigger,
      newNotification,
      }}>
      {props.children}
      <Notifier/> {/*call constructor and componentDidMount*/}
    </NotifierContext.Provider>
  );
};

export default NotifierContextProvider;