import React, { Suspense, useContext, useEffect, Fragment } from 'react';
import { useClearCache } from 'react-clear-cache';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { GuestContext } from '../contexts/GuestContext';
import CardMenu from './CardMenu';
import LoadingIndicator from './LoadingIndicator';
//import ConfirmPwdDialog from '../components/ConfirmPwdDialog';

const NavBar = React.lazy(() => import('./PublicNavbar'))
const InNavBar = React.lazy(() => import('./InNavBar'))
const AppDrawer = React.lazy(() => import('./AppDrawer'))
const Copyright = React.lazy(() => import('./Copyright'))
const LoggedOutPage = React.lazy(() => import('../views/logged-out-page'))
const ConfirmPwdDialog = React.lazy(() => import('../components/ConfirmPwdDialog'))

const drawerWidth = parseInt(process.env.REACT_APP_DRAWER_WIDTH)

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    width: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    padding: 0,
  },
  pageContainerShift: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    [theme.breakpoints.down(415)]: {
      width: '100%',
      marginLeft: 0,
    },
    padding: 0,
  },
  page: {
    flexGrow: 1,
    background: theme.palette.background.paper,
    width: '100%',
    'overflow-x': 'hidden', //remove extra unwanted space to child
  },
  pageF: {
    minHeight: '100vh',
    background: theme.palette.background.paper,
    width: '100%',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/background.svg'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

const GuestLayout = ({ children }) => {

  const classes = useStyles();

  const {
    contentRef,
    isDrawerOpen,
    isDDMenuOpen,
    setDDMenuState,
    logOutUser,
    setUserToken,
    userTokenEx,
    loginFlag,
    isLoggedOut,
    logoutMsg,
    showConfirmPwdDialog,
    isPrimaryPwd,
  } = useContext(GuestContext)

  useEffect(() => {
    //console.log('userTokenEx.current: ',userTokenEx.current)
    if (userTokenEx.current === null) {
      const storedToken = JSON.parse(localStorage.getItem('userToken'))
      if (storedToken && storedToken.tokenValue) {
        userTokenEx.current = storedToken.tokenValue
        setUserToken(userTokenEx.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginFlag])

  const { isLatestVersion, emptyCacheStorage } = useClearCache()
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage()
      console.log('new version detected...')
      //todo: create a visual notifier to manually refresh the cache, put it at the NotifierContext
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLatestVersion])

  return (
    <Fragment>
      <CssBaseline />
      <Suspense fallback={<LoadingIndicator/>}>
          <Fragment>
          {!userTokenEx.current?
              isLoggedOut===true?<LoggedOutPage extraMessage={logoutMsg}/>:
              <div className={classes.pageContainer}>
                <NavBar/>
                <div className={classes.pageF} >
                    {children[1]}
                </div>
                <Copyright/> 
              </div>:
              <div className={clsx(classes.pageContainer, {[classes.pageContainerShift]: isDrawerOpen})}>
                <AppDrawer/>
                <InNavBar>
                    <div ref={contentRef} className={classes.page}>
                        {children[2]}
                        <ConfirmPwdDialog 
                          isOpen={showConfirmPwdDialog} 
                          isPrimary={isPrimaryPwd} 
                          title={'User Authentication'} 
                          subTitle={isPrimaryPwd
                          ?'Please provide your primary password.'
                          :'Please provide your secondary password.'} 
                        />
                    </div>
                </InNavBar>   
                {isDDMenuOpen && (
                    <CardMenu open={isDDMenuOpen} setDDMenuState={setDDMenuState} logOutUser={logOutUser} />
                )}                      
              </div>}
          </Fragment>  
      </Suspense>
    </Fragment>
  );
}

export default GuestLayout;