const authHeader = (token) => { 
    if (token) {
        return { Authorization: 'Bearer ' + token } //return { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token }
    } else {
        return {};
    }
}

/*export const authHeaderZ = (token) => { 
    if (token) {
        return { Authorization: 'Bearer ' + token, 'Accept-Encoding': 'gzip'} 
    } else {
        return {};
    }
}*/

export default authHeader;