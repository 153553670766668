import SvgIcon from '@material-ui/core/SvgIcon';

export default function MCOIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 165.74 141.36">
			  <defs/>
			  <path d="M45.55 130.72A15.69 15.69 0 1 0 29.86 115a15.68 15.68 0 0 0 15.69 15.72ZM138.76 115a15.69 15.69 0 1 0 15.69-15.69A15.68 15.68 0 0 0 138.76 115ZM170.08 138.64h-31.26a12.73 12.73 0 0 0-10.72 5.85 2 2 0 0 0 .44 2.67 20.61 20.61 0 0 1 6.89 10.38 1.94 1.94 0 0 0 1.86 1.47H179a3.89 3.89 0 0 0 3.89-3.88v-3.7a12.79 12.79 0 0 0-12.81-12.79ZM61.18 138.64H29.92a12.79 12.79 0 0 0-12.79 12.79v3.7A3.89 3.89 0 0 0 21 159h41.71a1.94 1.94 0 0 0 1.86-1.47 20.61 20.61 0 0 1 6.89-10.38 2 2 0 0 0 .44-2.67 12.73 12.73 0 0 0-10.72-5.84ZM100 142.39a15.69 15.69 0 1 0-15.69-15.69A15.68 15.68 0 0 0 100 142.39Zm15.63 7.92H84.37a12.78 12.78 0 0 0-12.79 12.79v3.69a3.89 3.89 0 0 0 3.88 3.89h49.08a3.89 3.89 0 0 0 3.88-3.89v-3.69a12.78 12.78 0 0 0-12.79-12.79Z" class="cls-1" transform="translate(-17.13 -29.32)"/>
			  <path d="M152.81 29.32H47.8A7.78 7.78 0 0 0 40 37.1v52.8a1.92 1.92 0 0 0 2.19 1.91c5-.75 10.54.06 16.44 4a22.92 22.92 0 0 1 9.75 24.51 1.92 1.92 0 0 0 1.89 2.33h5.06a1.93 1.93 0 0 0 1.87-1.53 23.45 23.45 0 0 1 45.56 0 1.93 1.93 0 0 0 1.87 1.53h5a1.92 1.92 0 0 0 1.9-2.32 23 23 0 0 1 .58-12.67 23.7 23.7 0 0 1 12.27-13.74 22.34 22.34 0 0 1 13.94-2 1.92 1.92 0 0 0 2.2-1.93V37.1a7.78 7.78 0 0 0-7.71-7.78Zm-32.75 36.59L99.4 91.46a1.58 1.58 0 0 1-2.68-1.63l9.21-21.06H94.66a6 6 0 0 1-5-9.16l11.86-18.52c4.4-6.5 14.09-.31 10 6.42l-6 9.36H115a5.61 5.61 0 0 1 5.06 9.04Z" class="cls-1" transform="translate(-17.13 -29.32)"/>
			</svg>
		</SvgIcon>
	)
}