import { createContext, useState, useReducer } from 'react';

export const FileDirContext = createContext();

const FileDirContextProvider = (props) => {
  //---File Directory Helpers------------------------
  const RootSubDir = '/'
  const [subDir, setSubDir] = useState(RootSubDir)
  const [currentDir, setCurrentDir] = useState(``)
  const [previousDir, setPreviousDir] = useState(``)
  //---File Directory Table Helpers-------------------
  const [fileDTRows, setFileDTRowsValue] = useState([])
  const [fileDirFetchFlag, setFileDirFetchFlag] = useState(false)
  const [selectFileFlag, setSelectFileFlag] = useState(false)
  const [isDirLoading, setIsDirLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isUploadBtnClick, setIsUploadBtnClick] = useState(false)
  const [isFileStoreEnabled, setIsFileStoreEnabled] = useState(false)

  const onDirClickHandler = (RootFSDir, newDir) => {
    let upOneDir;
    //console.log('newDir: ',newDir)
    if (newDir !== RootFSDir) {
      upOneDir = newDir.substr(0, newDir.lastIndexOf('/'))
      if (upOneDir > RootFSDir) {
        upOneDir = upOneDir.substr(0, upOneDir.lastIndexOf('/'))
      }
      //console.log('upOneDir: ',upOneDir)
      setPreviousDir(upOneDir)
    }

    if (newDir.lastIndexOf('/') === newDir.length - 1)
      setCurrentDir(newDir.substr(0, newDir.lastIndexOf('/')))
    else
      setCurrentDir(newDir)

    if (newDir === RootFSDir) {
      setSubDir('/')
    } else {
      let newSubDir = newDir.replace(RootFSDir + '/', '/')
      if (newSubDir.lastIndexOf('/') !== newSubDir.length - 1)
        newSubDir += '/'
      setSubDir(newSubDir)
    }
    setSelectFileFlag(!selectFileFlag) //see useEffect @ FileTable
  } //endBlock OnDirClickHandler

  const [progressInfos, setProgressInfos] = useState([]) //for UI update

  const SelectedFileInfos = (state, action) => {
    switch (action.type) {
      case 'update':
        return [...action.payload]
      case 'reset':
        return []
      default:
        return state
    }
  }

  const [selectedFiles, setSelectedFiles] = useReducer(SelectedFileInfos, []);
  const [downloadReady, setDownloadReady] = useState(0)

  return (
    <FileDirContext.Provider value={{
		fileDTRows, setPreviousDir, previousDir, setCurrentDir, currentDir, setSubDir, subDir, fileDirFetchFlag, 
		selectedFiles, setSelectedFiles, setFileDTRowsValue, onDirClickHandler,setFileDirFetchFlag, 
		selectFileFlag, setSelectFileFlag, progressInfos, setProgressInfos, downloadReady, setDownloadReady, 
    isDirLoading, setIsDirLoading, isDownloading, setIsDownloading, isUploadBtnClick, setIsUploadBtnClick,
    isFileStoreEnabled, setIsFileStoreEnabled, 
		}}>
		  {props.children}
    </FileDirContext.Provider>
  );
};

export default FileDirContextProvider;