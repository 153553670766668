import SvgIcon from '@material-ui/core/SvgIcon';

export default function AppWorkIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 165.74 174.95">
			  <defs/>
			  <path d="M151 63.32c-1.34-5 7.41-14.19 4.88-18.58s-14.93-1.44-18.54-5.05-.61-16-5-18.54a3.77 3.77 0 0 0-1.9-.46c-4.37 0-11.09 5.48-15.54 5.48a4.68 4.68 0 0 1-1.14-.14c-4.84-1.3-8.41-13.51-13.66-13.51S91.18 24.73 86.34 26a4.68 4.68 0 0 1-1.14.14c-4.45 0-11.16-5.48-15.54-5.48a3.77 3.77 0 0 0-1.9.46c-4.44 2.58-1.44 14.93-5.05 18.54s-16 .61-18.54 5.05 6.22 13.58 4.88 18.58c-1.3 4.87-13.51 8.44-13.51 13.71a4.17 4.17 0 0 0 .81 2.3h31.54c-.06-.76-.12-1.52-.12-2.3a32.23 32.23 0 1 1 64.46 0c0 .78-.06 1.54-.12 2.3h31.54a4.17 4.17 0 0 0 .81-2.3c0-5.27-12.21-8.84-13.46-13.68ZM58.56 90.79H21.73a4.6 4.6 0 0 0-4.6 4.61v32.22a4.6 4.6 0 0 0 4.6 4.61h36.83a4.6 4.6 0 0 0 4.61-4.61V95.4a4.6 4.6 0 0 0-4.61-4.61Zm0 55.25H21.73a4.6 4.6 0 0 0-4.6 4.6v32.23a4.61 4.61 0 0 0 4.6 4.61h36.83a4.61 4.61 0 0 0 4.61-4.61v-32.23a4.6 4.6 0 0 0-4.61-4.64Zm59.86 0H81.58a4.6 4.6 0 0 0-4.6 4.6v32.23a4.61 4.61 0 0 0 4.6 4.61h36.84a4.61 4.61 0 0 0 4.6-4.61v-32.23a4.6 4.6 0 0 0-4.6-4.64Zm59.85-55.25h-36.83a4.6 4.6 0 0 0-4.61 4.61v32.22a4.6 4.6 0 0 0 4.61 4.61h36.83a4.6 4.6 0 0 0 4.6-4.61V95.4a4.6 4.6 0 0 0-4.6-4.61Zm0 55.25h-36.83a4.6 4.6 0 0 0-4.61 4.6v32.23a4.61 4.61 0 0 0 4.61 4.61h36.83a4.61 4.61 0 0 0 4.6-4.61v-32.23a4.6 4.6 0 0 0-4.6-4.64Zm-59.85-55.25H81.58A4.6 4.6 0 0 0 77 95.4v32.22a4.6 4.6 0 0 0 4.6 4.61h36.84a4.6 4.6 0 0 0 4.6-4.61V95.4a4.6 4.6 0 0 0-4.62-4.61Z" class="cls-1" transform="translate(-17.13 -12.52)"/>
			</svg>
		</SvgIcon>
	)
}