import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { /*createMuiTheme,*/ ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
//import { fade } from '@material-ui/core/styles';
import useDocumentTitle from './custom_hooks/useDocumentTitle';
import GuestLayout from './components/GuestLayout';
import GuestContextProvider from './contexts/GuestContext';
import FileDirContextProvider from './contexts/FileDirContext';
import PersonContextProvider from './contexts/PersonContext';
import NotifierContextProvider from './contexts/NotifierContext';
import ConsumerContextProvider from './contexts/ConsumerContext';
import LoadingIndicator from './components/LoadingIndicator';
//import { deepPurple, amber } from '@material-ui/core/colors'

const SignIn = React.lazy(() => import('./views/sign-in'))
const AuthBasic = React.lazy(() => import('./views/auth-basic'))
const ActivateUserView = React.lazy(() => import('./views/activate-user'))
const ContactUs = React.lazy(() => import('./views/contact-us'))
const NotFoundPage = React.lazy(() => import('./views/not-found-page'))

const Dashboard = React.lazy(() => import('./views/dash-board'))
const MyFiles = React.lazy(() => import('./views/user-files'))
const MyApps = React.lazy(() => import('./views/apps-listing'))
const ConsWelfare = React.lazy(() => import('./apps/cons-welfare'))
const PersonsData = React.lazy(() => import('./apps/person-data'))
const SysDataStore = React.lazy(() => import('./apps/data-store'))
const AccessManagement = React.lazy(() => import('./apps/access-management'))
const UserProfile = React.lazy(() => import('./views/user-profile'))
const UserSettings = React.lazy(() => import('./views/user-settings'))

const titlePrefix = process.env.REACT_APP_WEBSITE_NAME + ' | ';

const app_theme = createTheme({
  palette: {
    primary: {
      main: '#4A596A', //'#DA242C'//
      //contrastText: 'indigo',
    },
    secondary: {
      main: '#DD5145' //'#FF9800'
    },
    background: {
      default: '#555555' //'#8F8F8F',//'#03A027'//
    },
    captionTextColor: 'tomato',
    headerBackground: '#999999',//'#3D70B2',
  },
  typography: {
    fontFamily: "'Roboto','Quicksand','Helvetica','Arial','san-serif'"
  },
})

app_theme.overrides = {
  MuiInput: {
    underline: {
      "&:hover:not($disabled):before": {
        borderBottom: `1.5px solid #E06B71` //${deepPurple[900]}
      },
      "&:after": {
        borderBottom: `2px solid #E06B71` //${deepPurple[900]}
      }
    },
  },
  MuiPickersBasePicker: {
    pickerView: {
      backgroundColor: "white"
    }
  },
  MuiPickersDay: {
    day: {
      color: "light-gray",
      fontFamily: "\"Do Hyeon\", sans-serif",
      backgroundColor: "white",
      borderRadius: "12px",
    },
    container: {
      backgroundColor: "white"
    },
    daySelected: {
      backgroundColor: "tomato",
      color: "white",
      '&:hover': {
        color: "white",
        backgroundColor: "tomato",
      }
    },
    dayDisabled: {
      color: "grey",
    },
    current: {
      color: "light-gray",
    },
  },
  MuiInputBase: {
    input: {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      color: 'tomato',
    }
  }
}

/*app_theme.props = {
  MuiFilledInput: {
    disableUnderline: true
  }
};*/

const Home = () => {
  useDocumentTitle(`${titlePrefix}Sign-In`)
  return (
    <div>
      <Suspense fallback={<LoadingIndicator/>} >
        <SignIn />
      </Suspense>
    </div>
  )
}

const AuthLogin = () => {
  useDocumentTitle(`${titlePrefix}Sign-In`)
  return (
    <div>
      <Suspense fallback={<LoadingIndicator/>} >
      <AuthBasic />
      </Suspense>
    </div>
  )
}

const ActivateUserV = () => {
  useDocumentTitle(`${titlePrefix}User Activation`)
  return (
    <div>
      <Suspense fallback={<LoadingIndicator/>} >
      <ActivateUserView />
      </Suspense>
    </div>
  )
}

const Contact = () => {
  useDocumentTitle(`${titlePrefix}Contact Developer`)
  return (
    <div>
      <Suspense fallback={<LoadingIndicator/>} >
      <ContactUs />
      </Suspense> 
    </div>
  )
}

const AuthHome = () => {
  useDocumentTitle(`${titlePrefix}Dashboard`)
  return (
    <div>
      <Suspense fallback={<LoadingIndicator/>} >
      <Dashboard />
      </Suspense>
    </div>
  )
}

const MyFSDirectory = () => {
  useDocumentTitle(`${titlePrefix}My Files`)
  return (
    <FileDirContextProvider>
      <Suspense fallback={<LoadingIndicator/>} >
      <MyFiles />
      </Suspense>
    </FileDirContextProvider>
  )
}

const MyAppsList = () => {
  useDocumentTitle(`${titlePrefix}Applications`)
  return (
    <div>
      <Suspense fallback={<LoadingIndicator/>} >
      <MyApps />
      </Suspense>
    </div>
  )
}

const ConsumersWelfare = () => {
  useDocumentTitle(`${titlePrefix}Consumers Welfare`)
  return (
    <ConsumerContextProvider>
      <Suspense fallback={<LoadingIndicator/>} >
      <ConsWelfare />
      </Suspense>
    </ConsumerContextProvider>
  )
}

const PersonData = () => {
  useDocumentTitle(`${titlePrefix}PersonData`)
  return (
    <Suspense fallback={<LoadingIndicator/>} >
    <PersonsData />
    </Suspense>
  )
}

const AccessMgt = () => {
  useDocumentTitle(`${titlePrefix}Access Management`)
  return (
    <Suspense fallback={<LoadingIndicator/>} >
      <AccessManagement />
    </Suspense>
  )
}

const DataStore = () => {
  useDocumentTitle(`${titlePrefix}Data Store`)
  return (
    <Suspense fallback={<LoadingIndicator/>} >
      <SysDataStore />
    </Suspense>
  )
}

const ShowUserProfile = () => {
  useDocumentTitle(`${titlePrefix}User Profile`)
  return (
    <div>
      <Suspense fallback={<LoadingIndicator/>} >
      <UserProfile />
      </Suspense>
    </div>
  )
}

const ShowUsersProfile = () => {
  useDocumentTitle(`${titlePrefix}Users Profile`)
  return (
    <div>
      <Suspense fallback={<LoadingIndicator/>} >
      <UserProfile />
      </Suspense>
    </div>
  )
}

const ShowUserSettings = () => {
  useDocumentTitle(`${titlePrefix}Settings and Privacy`)
  return (
    <div>
      <Suspense fallback={<LoadingIndicator/>} >
      <UserSettings />
      </Suspense>
    </div>
  )
}

const GuestRoute = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/auth_login' component={AuthLogin} />
        <Route path='/activate_user' component={ActivateUserV} />
        <Route path='/contact_dev' component={Contact} />
        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  )
}

const AuthRoute = () => {
  return (
    <React.Fragment>
        <Switch>
          <Route exact path='/' component={AuthHome} />
          <Route path='/user-profile' component={ShowUserProfile} />
          <Route path='/users-profile' component={ShowUsersProfile} />
          <Route path='/user-settings' component={ShowUserSettings} />
          <Route path='/file directory' component={MyFSDirectory} />
          <Route path='/apps' component={MyAppsList} /> 
          <Route path='/app-cwf' component={ConsumersWelfare} />
          <Route path='/app-persons-data' component={PersonData} />
          <Route path='/app-access-mgt' component={AccessMgt} /> 
          <Route path='/app-data-store' component={DataStore} />
          <Route component={NotFoundPage} />
        </Switch>
    </React.Fragment>
  )
}

const App = () => {
  return (
    <ThemeProvider theme={app_theme}>
      <NotifierContextProvider>
        <Router>
          <GuestContextProvider>
            <PersonContextProvider>
              <GuestLayout>
                [
                  <GuestRoute/>
                  <AuthRoute/>
                ]
              </GuestLayout>
            </PersonContextProvider>
          </GuestContextProvider>
        </Router>
      </NotifierContextProvider>
    </ThemeProvider>
  )
}

export default App