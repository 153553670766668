import { axios } from '../utils/axios';
import authHeader from './auth_header';

class AuthService {

  PreSign(user_id) {
    return axios.get('/api/auth/presign/' + user_id)
  }

  SignIn(paramNext) {
    return axios.post('/api/auth/signin', paramNext)
  }

  ActivateUser(paramNext) {
    return axios.post('/api/auth/activate', paramNext)
  }

  RefreshToken(token) {
    return axios.get('/api/auth/refreshtoken', {
      params: {
        token: token,
      },
    }, { headers: authHeader(token) });
  }

  RevokeToken(token) {
    return axios.delete('/api/auth/revoketoken', { headers: authHeader(token) });
  }

  GetAppointmentId(token) {
    return axios.get('/api/auth/getappointmentid', {
      params: {
        token: token,
      },
    }, { headers: authHeader(token) });
  }

  ValidateUser(objData, token) {
    return axios.post('/api/auth/validate', objData, { headers: authHeader(token) })
  }//this function was use in ConfirmPwdDialog

} //class end block

export default new AuthService();