import React, { createContext, useReducer, useState, useRef } from 'react';
import { base64decode } from '../utils/base64';
import { removeSymbols } from '../utils/utils';
import PersonsService from '../services/persons_service';

export const PersonContext = createContext();

const PersonContextProvider = (props) => {

  let personDataStruct = {
    avatar: '',
    firstName: '',
    lastName: '',
    middleName: '',
    suffix: '',
    gender: null,
    PersonID: undefined,
    PData: {
      birthDate: 0, //store as unixDatetimeStamp
      birthPlaceSub: '', //city/municipality
      birthPlaceRoot: '', //province/region
      presentAddr1: '',
      presentAddr2: '',
      presentAddr3: '',
      presentAddr4: '',
      email1: '',
      email2: '',
      phone1: '',
      phone2: '',
      activate: 0,
      activationCode: '',
    },
    notActive: false,
  }
  const personData = useRef(personDataStruct)
  const btnSubmitRef = useRef(null)

  const EditorInfo = (state, action) => {
    switch (action.type) {
      case 'new':
        return 1
      case 'edit':
        return 2
      case 'delete':
        return 3
      case 'focus':
        return 6
      default:
        return 0 //'browse'
    }
  }
  const [editorState, setEditorState] = useReducer(EditorInfo, 0);
  const [editorBusy, setEditorBusy] = useState(false)
  const [clearDlgOpen, setClearDlgOpen] = useState(false)
  const [delDlgOpen, setDelDlgOpen] = useState(false)

  const [PersonsList, setPersonsList] = useState([])
  const [isFetching, setIsPetching] = useState(false)
  const [fetchError, setFetchError] = useState(undefined)
  const [personSelected, setPersonSelected] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [usersList, setUsersList] = useState([])

  const [PermissionList, setPermissionList] = useState([])
  const [bakPermissionList, setBakPermissionList] = useState([])
  const [isPermissionListing, setIsPermissionListing] = useState(false)
  const [showEditPermission, setShowEditPermission] = useState(false)
  const [userGroupName, setUserGroupName] = useState(null)
  const [bakGroupName, setBakGroupName] = useState(null)
  const [selectedMail, setSelectedMail] = useState('')
  const [UFS_MAX, setUFS_MAX] = useState(0)
  const [showUserDelDialog, setShowUserDelDialog] = useState(false)

  //add the PersonsService.SearchPerson() onSearchKeyHandler here
  const onSearchKeyHandler = async (keyName, token) => {
    setSelectedMail('') //unselect person in view by unsetting selectedMail
    
    if (keyName === '') {
      setPersonsList([])
      return
    }
    setFetchError(undefined)
    setIsPetching(true)

    let wasm = undefined
    try {
      wasm = await import('rlib2-wasm');
    } catch (error) {
      setFetchError(error)
      return
    }
    //console.log('start-> ', new Date())
    let keyValue = ''
    if (keyName.substring(0,2)==='a~') {
      keyValue = 'a~'+removeSymbols(keyName.substring(2))
    } else {
      keyValue = removeSymbols(keyName)//escapeRegex(keyName)
    }
    //console.log(keyValue)
    PersonsService.SearchPerson(keyValue, token).then(response => {
      if (response.data) {
        //console.log('end-> ', new Date())
        const JSONStr = wasm.unHashMCBase64(response.data) //decode step 1
        const PData = base64decode(JSONStr) //decode step 2
        //console.log(PData)
        setPersonsList(JSON.parse(PData))
      } else {
        throw new Error(response.data)
      }
    }).catch((error) => {
      //console.log('Error@onSearchKeyHandler: ',error)
      setFetchError(error) //use FetchError as dependecy to useEffect to monitor error at the context consumer view
    }).finally(() => setIsPetching(false))
  }

  const onSearchBoxKeyDown = (e, token) => {
    if (e.key === "Enter") {
      onSearchKeyHandler(e.target.value, token)
      //setSearchValue('')
    }
  }

  const onListUserHandler = async (keyName,listMode,token) => {
    let keyValue = ''
    if (keyName.substring(0,2)==='a~') {
      keyValue = 'a~'+removeSymbols(keyName.substring(2))
    } else {
      keyValue = removeSymbols(keyName)//escapeRegex(keyName)
    }

    setFetchError(undefined)
    setIsPetching(true)

    await PersonsService.ListUser(keyValue, listMode, token).then(response => {
      if (response.data) {
        //console.log(response.data)
        setUsersList(response.data)
      } else {
        throw new Error(response.data)
      }
    }).catch((error) => {
      setFetchError(error) //use FetchError as dependecy to useEffect to monitor error at the context consumer view
    }).finally(() => setIsPetching(false))
  }

  return (
    <PersonContext.Provider value={{
      personData, 
      editorState,
      setEditorState,
      btnSubmitRef,
      clearDlgOpen, 
      setClearDlgOpen,
      editorBusy, 
      setEditorBusy,
      PersonsList,
      setPersonsList,
      isFetching,
      setIsPetching,
      fetchError,
      setFetchError,
      onSearchKeyHandler,
      onSearchBoxKeyDown,
      personSelected, 
      setPersonSelected,
      delDlgOpen, 
      setDelDlgOpen,
      searchValue, 
      setSearchValue,
      PermissionList, 
      setPermissionList,
      isPermissionListing, 
      setIsPermissionListing,
      showEditPermission, 
      setShowEditPermission,
      userGroupName, 
      setUserGroupName,
      selectedMail, 
      setSelectedMail,
      bakPermissionList, 
      setBakPermissionList,
      bakGroupName, 
      setBakGroupName,
      UFS_MAX, 
      setUFS_MAX,
      showUserDelDialog, 
      setShowUserDelDialog,
      onListUserHandler,
      usersList, 
      setUsersList,
    }}>
    {props.children}
    </PersonContext.Provider>
  );
};

export default PersonContextProvider;