import SvgIcon from '@material-ui/core/SvgIcon';

export default function DataStoreIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 165.74 164.28">
			  <defs/>
			  <path d="M73.17 143.71 53.25 155a2.47 2.47 0 0 0-1.25 2.17v22.49a2.5 2.5 0 0 0 3.73 2.15l19.91-11.31a2.47 2.47 0 0 0 1.26-2.15v-22.49a2.5 2.5 0 0 0-3.73-2.15ZM40.78 155l-19.93-11.29a2.49 2.49 0 0 0-3.72 2.15v22.49a2.48 2.48 0 0 0 1.25 2.15l19.93 11.31a2.49 2.49 0 0 0 3.69-2.15v-22.49a2.48 2.48 0 0 0-1.22-2.17Zm27.28-24.1-19.81-11.23a2.53 2.53 0 0 0-2.47 0L26 130.92a2.46 2.46 0 0 0 0 4.29l19.81 11.25a2.48 2.48 0 0 0 2.47 0l19.81-11.25a2.46 2.46 0 0 0-.03-4.29Zm111.09 12.79L159.22 155a2.48 2.48 0 0 0-1.25 2.15v22.49a2.49 2.49 0 0 0 3.72 2.15l19.93-11.31a2.48 2.48 0 0 0 1.25-2.15v-22.47a2.49 2.49 0 0 0-3.72-2.15ZM174 130.92l-19.81-11.25a2.53 2.53 0 0 0-2.47 0l-19.81 11.25a2.46 2.46 0 0 0 0 4.29l19.81 11.25a2.48 2.48 0 0 0 2.47 0L174 135.21a2.46 2.46 0 0 0 0-4.29ZM146.75 155l-19.92-11.31a2.5 2.5 0 0 0-3.73 2.15v22.49a2.47 2.47 0 0 0 1.26 2.15l19.92 11.31a2.5 2.5 0 0 0 3.73-2.15v-22.47a2.47 2.47 0 0 0-1.26-2.17ZM102.88 17.86c-16.39 0-29.67 6.6-29.67 14.74v14l1.16 1.44c3.41 4.23 13.79 8.79 28.51 8.79s25.52-4.6 28.78-9.14l.9-1.24V32.6c0-8.14-13.29-14.74-29.68-14.74Zm0 21.06c-9.36 0-16.95-2.83-16.95-6.32s7.59-6.32 16.95-6.32 17 2.83 17 6.32-7.63 6.32-17 6.32Zm17.84 84.3-12.67-7.6v-11.76a5.3 5.3 0 0 0-10.6 0v11.72L85 123a5.25 5.25 0 0 0-1.8 7.23 5.32 5.32 0 0 0 7.32 1.77l12.19-7.31 12.53 7.52a5.32 5.32 0 0 0 7.28-1.78 5.26 5.26 0 0 0-1.8-7.21Zm-47.51-62.7v14.2c0 8.14 13.28 14.74 29.67 14.74s29.68-6.6 29.68-14.74V60.46c-7.4 4.33-18 6.94-29.68 6.94s-22.3-2.55-29.67-6.88Z" class="cls-1" transform="translate(-17.13 -17.86)"/>
			</svg>
		</SvgIcon>
	)
}