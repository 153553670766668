import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalendarIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 165.74 159.62">
			  <defs/>
			  <path d="M162.08 38.79h-7.42v19.5a13.29 13.29 0 0 1-13.38 13.12 13.29 13.29 0 0 1-13.38-13.12v-19.5h-14.58v19.5a13.38 13.38 0 0 1-26.76 0v-19.5H72v19.5a13.38 13.38 0 0 1-26.76 0v-19.5h-7.32a20.61 20.61 0 0 0-20.79 20.38v100.26a20.61 20.61 0 0 0 20.79 20.38h124.16a20.61 20.61 0 0 0 20.79-20.38V59.17a20.61 20.61 0 0 0-20.79-20.38Zm7.16 120.64a7.1 7.1 0 0 1-7.16 7H37.92a7.1 7.1 0 0 1-7.16-7V82.15h138.48Z" class="cls-1" transform="translate(-17.13 -20.19)"/>
			  <path d="M58.61 74.83a16.73 16.73 0 0 1-16.88-16.54V36.73a16.88 16.88 0 0 1 33.75 0v21.56a16.73 16.73 0 0 1-16.87 16.54Zm0-47.79a9.81 9.81 0 0 0-9.89 9.69v21.56a9.89 9.89 0 0 0 19.78 0V36.73A9.81 9.81 0 0 0 58.61 27ZM99.94 74.83a16.73 16.73 0 0 1-16.87-16.54V36.73a16.88 16.88 0 0 1 33.75 0v21.56a16.73 16.73 0 0 1-16.88 16.54Zm0-47.79a9.8 9.8 0 0 0-9.88 9.69v21.56a9.89 9.89 0 0 0 19.77 0V36.73A9.81 9.81 0 0 0 99.94 27ZM141.28 74.83a16.73 16.73 0 0 1-16.88-16.54V36.73a16.88 16.88 0 0 1 33.75 0v21.56a16.73 16.73 0 0 1-16.87 16.54Zm0-47.79a9.81 9.81 0 0 0-9.89 9.69v21.56a9.89 9.89 0 0 0 19.77 0V36.73a9.8 9.8 0 0 0-9.88-9.73Z" class="cls-1" transform="translate(-17.13 -20.19)"/>
			  <path d="M28.27 75.34H56.4v24.83H28.27zM69.11 75.34h28.13v24.83H69.11zM109.95 75.34h28.13v24.83h-28.13zM28.27 109.34H56.4v24.83H28.27zM69.11 109.34h28.13v24.83H69.11zM109.95 109.34h28.13v24.83h-28.13z" class="cls-1"/>
			</svg>
		</SvgIcon>
	)
}